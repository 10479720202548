<template>
  <div v-loading="loading" class="asnBillDetail">

    <div class="headerBtns">
      <el-button size="small" type="primary" round icon="iconfont icon-zuo" @click="$router.go(-1)"> 返回
      </el-button>
      <!-- <el-button size="small" type="success" icon="iconfont icon-xinzeng" @click="exportAns()"> 导出
      </el-button> -->
      <!-- <el-button   size="small" type="warning"   icon="iconfont icon-qc"
                 @click="createOut()"> 采购退货
      </el-button> -->
      <el-button size="small" type="warning" icon="iconfont icon-qc" @click="printAsnBill()"> 打印入库单
      </el-button>


      <!-- <el-button size="small" type="primary"   icon="iconfont icon-zuo" @click="$router.go(-1)"> 返回</el-button>
      <el-button v-if="asnMaster.insertType === -2" size="small" type="info"   icon="iconfont icon-tijiao"
                 @click="showUpdateModal"> 修改
      </el-button>
      <el-button v-if="asnMaster.insertType === -2" size="small" type="success"   icon="iconfont icon-xinzeng"
                 @click="isCommit()"> 提交
      </el-button>
      <el-button size="small" type="danger"   icon="iconfont icon-shanchu" @click="isDelete(asnMaster.asnMasterId)">
        删除
      </el-button>
      <el-button v-if="!isFile" size="small" type="info" plain   icon="iconfont icon-print" @click="printList"
                 :disabled="multipleSelection.length === 0"> 打印
      </el-button>
      <el-button v-if="!isFile && asnMaster.insertType === 0 && asnMaster.qcState === -1" size="small" type="warning"   icon="iconfont icon-qc"
                 @click="showQcModal"> 质检
      </el-button>
      <el-button v-if="(asnMaster.state === 0 || asnMaster.state === 2) && formReview.caseDetailId !== null" size="small" type="warning"  
                 icon="iconfont icon-shenhe" @click="dialogReviewVisible = true"> 审核
      </el-button> -->
      <!-- <el-button   size="small" type="warning"   icon="iconfont icon-qc"
                 @click="showQcModal"> 送检
      </el-button> -->
    </div>

    <el-dialog title="修改入库单" :visible.sync="dialogNewVisible" :before-close="handleClose" width="900px"
      style="margin-top: -10vh;">
      <!--master-->
      <div class="bigFormBox2">
        <el-form class="clearFloat" :model="form.asnMaster" :rules="rules" ref="form.asnMaster">
          <el-form-item label="供应商" prop="supplierId" :label-width="formLabelWidth">
            <el-select v-model="form.asnMaster.supplierId" filterable placeholder="请选择供应商" style="width: 70%;">
              <el-option v-for="item in suppliers" :key="item.supplierId" :label="item.supplierName"
                :value="item.supplierId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="月台" prop="platformCode" :label-width="formLabelWidth">
            <el-select v-model="form.asnMaster.platformCode" filterable placeholder="请选择月台" style="width: 70%;">
              <el-option v-for="item in platforms" :key="item.platformCode" :label="item.label"
                :value="item.platformCode">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="承运方" prop="carrierCode" :label-width="formLabelWidth">
            <el-select v-model="form.asnMaster.carrierCode" filterable placeholder="请选择承运方" style="width: 70%;">
              <el-option v-for="item in carriers" :key="item.carrierCode" :label="item.carrierName"
                :value="item.carrierCode">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入库单类型" prop="asnType" :label-width="formLabelWidth">
            <el-select v-model="form.asnMaster.asnType" filterable placeholder="请选择入库单类型" style="width: 70%;">
              <el-option v-for="item in asnTypes" :key="item.dataDictDetailId" :label="item.dictValue"
                :value="item.dataDictDetailId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="优先级" :label-width="formLabelWidth">
            <el-input v-model="form.asnMaster.orderNo" placeholder="请输入优先级"></el-input>
          </el-form-item>
          <el-form-item label="交货地址" :label-width="formLabelWidth">
            <el-input v-model="form.asnMaster.deliveryPlace" placeholder="请输入交货地址"></el-input>
          </el-form-item>
          <el-form-item label="运输描述" :label-width="formLabelWidth">
            <el-input v-model="form.asnMaster.trafficDescribe" placeholder="请输入运输描述"></el-input>
          </el-form-item>
          <el-form-item label="备注" :label-width="formLabelWidth">
            <el-input v-model="form.asnMaster.memo" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item v-for="(item, index) in asnUdfHs" :key="index" :label="item.value" :label-width="formLabelWidth">
            <el-input v-model="form.asnMaster[item.paramCode]" :placeholder="'请输入' + item.value"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!--detail-->
      <div class="headerBtns">
        <el-button size="small" type="primary" icon="iconfont icon-xinzeng" @click="showSelectItemModal"> 选择物料
        </el-button>
      </div>

      <div>
        <el-table :height="clientHeight - 280" stripe highlight-current-row :data="form.asnDetails" style="width: 100%">
          <el-table-column fixed="left" type="index" width="50">
          </el-table-column>
          <el-table-column fixed="left" show-overflow-tooltip prop="itemName" label="物料名称" width="180">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="itemCode" label="物料编码" width="180">
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="expectQuantity" label="预约数量" width="180">
            <template slot-scope="scope">
              <el-input-number v-model="scope.row.expectQuantity" controls-position="right" :min="0" label="请输入数量"
                size="small" style="width: 90%;"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="packDescribe" label="单位" width="180">
            <template slot-scope="scope">
              <el-select v-model="scope.row.packDetailId" @change="changePackDetailId(scope.$index, 'update')" filterable
                placeholder="请选择单位" size="small">
                <el-option v-for="item in form.asnDetails[scope.$index].packDetails" :key="item.packDetailId1"
                  :label="item.packDescribe1" :value="item.packDetailId1">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip v-for="(item, index) in asnUdfDs" :key="index" :prop="item.paramCode"
            :label="item.value" width="180">
            <template slot-scope="scope">
              <el-input v-model="scope.row[item.paramCode]" :placeholder="'请输入' + item.label" size="small" clearable>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="60">
            <template slot-scope="scope">
              <el-button @click="deleteDetail(scope.$index)" :disabled="form.asnDetails.length === 1"
                icon="iconfont icon-shanchu" type="danger" circle plain size="small"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-dialog width="800px" title="选择物料" :visible.sync="innerVisibleItem" append-to-body style="margin-top: -10vh;">

        <div class="publicSearch body">
          <div class="searchBar" :style="{ height: isMoreSearch2 ? 'auto' : '' }">
            <el-button-group>
              <el-button @click="getItemData" size="mini" icon="el-icon-search">查询</el-button>
              <el-button @click="clearSearchSelectItem" size="mini" icon="el-icon-refresh">重置</el-button>
            </el-button-group>
            <div>
              <span>关键字搜索</span>
              <el-input v-model="searchDataItem.keyWords" size="mini" placeholder="名称/编码" clearable></el-input>
            </div>
          </div>
          <span class="moreSearchBtn" :class="isMoreSearch2 ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
            @click="isMoreSearch2 = !isMoreSearch2"></span>
        </div>

        <div>
          <el-table max-height="460" :data="items" style="width: 100%;">
            <el-table-column fixed="left" label="操作" width="60">
              <template slot-scope="scope">
                <el-button @click="selectItem(scope.row)" icon="iconfont icon-jia1" type="primary" circle plain
                  size="small"></el-button>
              </template>
            </el-table-column>
            <el-table-column fixed="left" type="index" width="50">
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip fixed="left" prop="itemName" label="物料名称" width="180">
              <template slot-scope="scope">
                <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip prop="itemCode" label="物料编码" width="180">
              <template slot-scope="scope">
                <span class="textBtn" @click="$parent.showPublicModal(scope.row.itemCode, 'item')">{{ scope.row.itemCode
                }}</span>
              </template>
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip prop="itemTypeName" label="物料种类" width="180">
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip prop="spec" label="规格" width="180">
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip prop="model" label="型号" width="180">
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip prop="packName" label="包装名称" width="180">
            </el-table-column>

          </el-table>
          <el-pagination style="text-align: right;" @current-change="handleCurrentChange2"
            :current-page.sync="searchDataItem.pageNum" :page-size="searchDataItem.pageSize"
            layout="total, prev, pager, next, jumper" :total="searchDataItem.total">
          </el-pagination>
        </div>

      </el-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogNewVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateForm">修 改</el-button>
      </div>
    </el-dialog>
    <el-dialog title="生成送检单据" :visible.sync="dialogQcVisible" :before-close="handleClose2" width="1100px"
      style="margin-top: -10vh;">
      <!--master-->
      <div class="bigFormBox2">
        <el-form class="clearFloat" :model="formQc.qcMaster" :rules="rulesQc" ref="formQc.qcMaster">
          <el-form-item label="入库单号" prop="billNo" :label-width="formLabelWidth">
            <el-input v-model="formQc.qcMaster.billNo" placeholder="请输入入库单号" disabled></el-input>
          </el-form-item>
          <!-- <el-form-item label="仓库" prop="wareId" :label-width="formLabelWidth">
            <el-select v-model="formQc.qcMaster.wareId" filterable placeholder="请选择仓库" disabled style="width: 70%;">
              <el-option :key="formQc.qcMaster.wareId" :label="formQc.qcMaster.wareName"
                :value="formQc.qcMaster.wareId">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="工厂" prop="organizationCode" :label-width="formLabelWidth">
            <div>{{ formQc.qcMaster.organizationCode }}{{ formQc.qcMaster.organizationName }}</div>

          </el-form-item>
          <el-form-item label="供应商" prop="supplierCode" :label-width="formLabelWidth">
            <div>{{ formQc.qcMaster.supplierCode }}{{ formQc.qcMaster.supplierName }}</div>
          </el-form-item>
          <!-- <el-form-item label="质检类型" prop="qcType" :label-width="formLabelWidth">
            <el-select v-model="formQc.qcType" filterable placeholder="请选择质检类型" style="width: 70%;">
              <el-option v-for="item in qcTypes" :key="item.dataDictDetailId" :label="item.dictValue"
                :value="item.dataDictDetailId">
              </el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="备注" prop="memo" :label-width="formLabelWidth">
            <el-input v-model="formQc.qcMaster.memo" placeholder="请输入备注"></el-input>
          </el-form-item>
          <!-- <el-form-item v-for="(item,index) in qcUdfHs" :key="index" :label="item.value" :label-width="formLabelWidth">
            <el-input v-model="formQc.qcMaster[item.paramCode]" :placeholder="'请输入' + item.value"></el-input>
          </el-form-item> -->
        </el-form>
      </div>
      <!--detail-->
      <!-- {{ formQc.qcDetails }} -->
      <el-table :header-cell-style="$parent.tableHeaderColor" highlight-current-row stripe border :data="formQc.qcDetails"
        max-height="360" style="width: 100%">
        <el-table-column fixed="left" type="index" width="50">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="itemCode" label="物料" width="120">
          <template slot-scope="scope">
            <div>{{ scope.row.itemCode }}</div>
            <div>{{ scope.row.itemName }}</div>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="qcType" label="类型" width="60">
          <template slot-scope="scope">
            <span v-for="(item, index) in qcTypes" :key="index" v-if="item.dictKey == scope.row.qcType">{{ item.dictValue
            }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="quantity" label="采购数" width="100">
          <template slot-scope="scope">
            <span>{{ Math.floor(scope.row.expectQuantity / scope.row.transRatio * 10000) / 10000 }}</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="dhQuantity" label="到货数" width="120">
          <template slot-scope="scope">
            <input type="number" class="my_input small" v-model.number="scope.row.dhQuantity" placeholder="到货数量"
              style="width: 100%;">
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="packDescribe" label="单位" width="100">
          <template slot-scope="scope">
            <el-input v-model="scope.row.packDetailCode" placeholder="" size="small" disabled=true></el-input>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="batchName" label="批次" width="150">
          <template slot-scope="scope">
            <input class="my_input small" v-model.number="scope.row.batchName" placeholder="批次" style="width: 80%;">
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="memo" label="备注" min-width="150">
          <template slot-scope="scope">
            <input class="my_input small" v-model.number="scope.row.memo" placeholder="备注" style="width: 80%;">
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="wareCode" label="仓库" width="150">
          <template slot-scope="scope">
            <el-select v-model="scope.row.wareCode" auto-complete="" filterable placeholder="请选择仓库" size="small">
              <el-option v-for="item in wares" :key="item.wareCode" :label="item.wareCode + item.wareName"
                :value="item.wareCode">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="detailNo" label="行号">
          <template slot-scope="scope">
            <div>
              {{ scope.row.allotDetailNo }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="asnDetailId" label="AsnId">
          <template slot-scope="scope">
            <div>
              {{ scope.row.asnDetailId }}
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div slot="footer" class="dialog-footer">
        <!-- <el-button class="leftFloat" type="primary" plain @click="commitFormQc('formQc.qcMaster',-2)">保存为草稿</el-button> -->
        <el-button @click="handleClose2">取 消</el-button>
        <el-button type="primary" @click="commitFormQc('formQc.qcMaster', 0)">提 交</el-button>
      </div>
    </el-dialog>

    <el-dialog title="审核" :visible.sync="dialogReviewVisible" :before-close="handleClose3" width="500px">
      <el-form :model="formReview" :rules="rulesReview" ref="formReview">
        <el-form-item label="审核意见" prop="memo" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="formReview.memo" placeholder="请输入审核意见"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="leftFloat" @click="dialogReviewVisible = false">取 消</el-button>
        <el-button type="danger" @click="commitReview('formReview', -1)">退 回</el-button>
        <el-button type="success" @click="commitReview('formReview', 1)">通 过</el-button>
      </div>
    </el-dialog>
    <el-dialog title="入库单验收" :visible.sync="dialogAcceptVisible" :before-close="handleClose4" width="900px"
      style="margin-top: -10vh;">
      <div class="cellModalBox">
        <el-form class="clearFloat" :model="formAccept" :rules="rulesAccept" ref="formAccept">
          <el-form-item label="仓库" prop="wareId" :label-width="formLabelWidth">
            <span>{{ formAccept.wareName }}</span>

          </el-form-item>
          <el-form-item label="工厂" prop="organizationId" :label-width="formLabelWidth">
            <span>{{ formAccept.organizationName }}</span>
          </el-form-item>
          <el-form-item label="物料名称" prop="itemName" :label-width="formLabelWidth">
            <span>{{ formAccept.itemName }}</span>
          </el-form-item>
          <el-form-item label="物料编码" prop="itemCode" :label-width="formLabelWidth">
            <span>{{ formAccept.itemCode }}</span>
          </el-form-item>
          <el-form-item label="预约数 " prop="expectQuantity+'/'+acceptQuantity" :label-width="formLabelWidth">
            <span>{{ formAccept.expectQuantity / formAccept.transRatio }}{{ formAccept.packDescribe }}/{{
              formAccept.expectQuantity }}主单位</span>
          </el-form-item>
          <el-form-item label="已验收数量" prop="acceptQuantity" :label-width="formLabelWidth">
            <span>{{ formAccept.acceptQuantity }}（主单位）</span>
          </el-form-item>
          <el-form-item label="单位" prop="toPackDetailId" :label-width="formLabelWidth">
            <el-select v-model="formAccept.toPackDetailId" @change="changePackDetailId(0, 'accept')" filterable
              placeholder="请选择单位" style="width: 90%;">
              <el-option v-for="item in packDetails" :key="item.packDetailId" :label="item.packDescribe"
                :value="item.packDetailId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="验收数量" prop="toQuantity" :label-width="formLabelWidth">
            <el-input-number v-model="formAccept.toQuantity" controls-position="right" :min="0"
              :max="formAccept.toQuantityMax * acceptExcess" label="请输入数量" style="width: 90%;"></el-input-number>
          </el-form-item>
          <el-form-item label="验收数量" prop="toQuantity" :label-width="formLabelWidth">
            <span style="color:red">{{ formAccept.toQuantity * formAccept.toTransRatio }}（主单位） </span>
          </el-form-item>

          <el-form-item label="追踪号" prop="toBoxCode" :label-width="formLabelWidth">
            <el-input v-model="formAccept.toBoxCode" placeholder="请输入追踪号" style="width: 90%;"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="memo" :label-width="formLabelWidth">
            <el-input v-model="formAccept.memo" placeholder="请输入备注" style="width: 90%;"></el-input>
          </el-form-item>
          <el-form-item label="货位编码" prop="toCellCode" :label-width="formLabelWidth">
            <el-input :title="formAccept.toCellCode" v-model="formAccept.toCellCode" placeholder="请输入编码"
              style="width: 80%;"></el-input>
            <el-button @click="showSelectCellModal()" icon="iconfont icon-jia" type="text" style="width: 10%;">
            </el-button>
          </el-form-item>
          <div style="float: left;width: 100%;">
            <el-divider content-position="left">批次策略</el-divider>
          </div>
          <div v-if="batchTactics.batchTacticDetails === undefined
            || batchTactics.batchTacticDetails === null
            || batchTactics.batchTacticDetails.lenght === 0"
            style="float: left;width: 100%;line-height: 60px;color: #909399;text-align: center;">无可用属性
          </div>
          <el-form-item v-for="(item, index) in batchTactics.batchTacticDetails" :key="index" :label="item.detailName"
            :prop="item.detailCode" :label-width="formLabelWidth">
            <el-select v-if="item.detailCode === 'qcState'" v-model="formAccept[item.detailCode]" filterable
              :placeholder="'请选择' + item.detailName" style="width: 90%;">
              <el-option v-for="item2 in qcStates" :key="item2.value" :label="item2.label" :value="item2.value">
              </el-option>
            </el-select>

            <el-input :title="formAccept[item.detailCode]" v-else-if="item.format === 0"
              v-model="formAccept[item.detailCode]"
              :disabled="item.detailCode === 'detailNo' || item.detailCode === 'supplierCode'"
              :placeholder="'请输入' + item.detailName" style="width: 90%;"></el-input>
            <el-input-number v-else-if="item.format === 1" v-model="formAccept[item.detailCode]" controls-position="right"
              :min="0" label="请输入" style="width: 90%;"></el-input-number>
            <el-date-picker v-else-if="item.format === 2" v-model="formAccept[item.detailCode]" value-format="yyyy-MM-dd"
              type="date" :placeholder="'请选择' + item.detailName" style="width: 90%;">
            </el-date-picker>
            <el-time-picker v-else-if="item.format === 3" v-model="formAccept[item.detailCode]" value-format="HH:mm:ss"
              :placeholder="'请选择' + item.detailName" style="width: 90%;"></el-time-picker>
            <el-select v-else-if="item.format === 4" v-model="formAccept[item.detailCode]" filterable
              :placeholder="'请选择' + item.detailName" style="width: 90%;">
              <el-option v-for="item2 in item.values" :key="item2.batchTacticDetailDetailValue"
                :label="item2.batchTacticDetailDetailValue" :value="item2.batchTacticDetailDetailValue">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose4">取 消</el-button>
        <!--        <el-button type="primary" @click="commitBatchTactic('form')">确 定</el-button>-->
        <el-button type="primary" @click="commitAccept()">验 收</el-button>
      </div>
    </el-dialog>
    <el-dialog width="1200px" title="选择货位" :visible.sync="innerVisibleCell" style="margin-top: -10vh;">

      <div class="publicSearch body">
        <div class="searchBar" :style="{ height: isMoreSearch3 ? 'auto' : '' }">
          <el-button-group>
            <el-button @click="getCellData" size="mini" icon="el-icon-search">查询</el-button>
            <el-button @click="clearSearchSelectCell" size="mini" icon="el-icon-refresh">重置</el-button>
          </el-button-group>
          <div>
            <span>关键字搜索</span>
            <el-input v-model="searchSelectCell.keyWords" size="mini" placeholder="名称" clearable></el-input>
          </div>
          <div>
            <span>货区</span>
            <el-select v-model="searchSelectCell.areaId" size="mini" filterable placeholder="请选择货区" style="width: 70%;">
              <el-option v-for="item in areas" :key="item.areaId" :label="item.areaName" :value="item.areaId">
              </el-option>
            </el-select>
          </div>
        </div>
        <span class="moreSearchBtn" :class="isMoreSearch3 ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
          @click="isMoreSearch3 = !isMoreSearch3"></span>
      </div>

      <div>
        <el-table :header-cell-style="$parent.tableHeaderColor" highlight-current-row :data="cells" style="width: 100%">
          <el-table-column fixed="left" label="操作" width="110">
            <template slot-scope="scope">
              <el-button @click="selectCell(scope.row.cellCode)" icon="iconfont icon-jia1" type="primary" circle plain
                size="small"></el-button>
            </template>
          </el-table-column>

          <el-table-column fixed="left" prop="cellName" label="名称" width="180">
          </el-table-column>
          <el-table-column prop="cellCode" label="编码" width="180">
          </el-table-column>
          <el-table-column prop="shelfName" label="所属货位组" width="180">
          </el-table-column>
          <el-table-column prop="cellUseType" label="货位使用类型" width="180">
            <template slot-scope="scope">
              <span v-for="(item, index) in cellUseTypes" :key="index" v-if="item.value === scope.row.cellUseType">{{
                item.label }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="cellAbcLevel" label="周转需求" width="180">
            <template slot-scope="scope">
              <span v-for="(item, index) in cellAbcLevels" :key="index" v-if="item.value === scope.row.cellAbcLevel">{{
                item.label }}</span>
            </template>
          </el-table-column>


        </el-table>
        <el-pagination style="text-align: right;" @current-change="handleCurrentChange3"
          :current-page.sync="searchSelectCell.pageNum" :page-size="searchSelectCell.pageSize"
          layout="total, prev, pager, next, jumper" :total="searchSelectCell.total">
        </el-pagination>
      </div>

    </el-dialog>

    <div class="publicSearch">
      <div class="searchBar" :style="{ height: isMoreSearchMaster ? 'auto' : '' }">
        <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 100%;">
          <el-tab-pane :label="isFile ? '入库单归档单据信息' : '入库单据信息'" name="first">
            <div class="newBillData clearFloat">
              <ul class="leftFloat">
                <li>
                  <span>单号:</span>
                  <span :title="asnMaster.billNo">&nbsp;{{ asnMaster.billNo }}</span>
                </li>
                <!-- <li>
                  <span>单据来源:</span>
                  <span v-if="asnMaster.billSource === 0">采购单</span>
                  <span v-else-if="asnMaster.billSource === 1">新建</span>
                </li> -->
                <!-- <li>
                  <span>入库单类型:</span>
                  <span v-for="(item,index) in asnTypes" :key="index"
                        v-if="asnMaster.asnType === item.dataDictDetailId">{{item.dictValue}}</span>
                </li> -->
                <li>
                  <span>创建人:</span>
                  <span :title="asnMaster.createUserName" class="textBtn"
                    @click="$parent.showPublicModal(asnMaster.createUserId, 'account')">{{ asnMaster.createUserName
                    }}</span>
                </li>
                <li>
                  <span>创建时间:</span>
                  <span :title="asnMaster.createTime1">&nbsp;{{ asnMaster.createTime1 }}</span>
                </li>
                <li v-if="asnMaster.billSource === 0">
                  <span>采购单号:</span>
                  <span :title="asnMaster.requestBuyBillNo" class="textBtn"
                    @click="$router.push({ name: 'buyBillInDetail', params: { billNo: asnMaster.requestBuyBillNo } })">{{
                      asnMaster.requestBuyBillNo }}</span>
                </li>
                <!-- <li v-if="asnMaster.insertType === 0">
                  <span>审核状态:</span>&nbsp;
                  <span v-if="asnMaster.state === -2">草稿</span>
                  <span v-else-if="asnMaster.state === 2">待审核</span>
                  <span v-else-if="asnMaster.state === 1">审核通过</span>
                  <span v-else-if="asnMaster.state === 0">审核中</span>
                  <span v-else-if="asnMaster.state === -1">审核退回</span>
                  <span v-else-if="asnMaster.state === 6">已生成QC单</span>
                </li> -->
                <!-- <li>
                  <span>保存类型:</span>&nbsp;
                  <span v-if="asnMaster.insertType === 0">单据</span>
                  <span v-else-if="asnMaster.insertType === -2">草稿</span>
                </li> -->
                <!-- <li>
                  <span>发货地址:</span>
                  <span :title="asnMaster.loadingPlace">&nbsp;{{asnMaster.loadingPlace}}</span>
                </li> -->
              </ul>
              <ul class="leftFloat">
                <li>
                  <span>工厂:</span>
                  <span :title="asnMaster.organizationName" class="textBtn"
                    @click="$parent.showPublicModal(asnMaster.organizationId, 'organization')">{{
                      asnMaster.organizationName }}</span>
                </li>
                <li>
                  <span>供应商:</span>
                  <!-- <span :title="asnMaster.supplierName" class="textBtn"
                    @click="$parent.showPublicModal(asnMaster.supplierId, 'supplier')">{{asnMaster.supplierName}}</span> -->
                  <span :title="asnMaster.supplierName">{{ asnMaster.supplierName }}</span>
                </li>

                <!-- <li>
                  <span>预期到货时间:</span>
                  <span :title="asnMaster.expectTime1">&nbsp;{{asnMaster.expectTime1}}</span>
                </li> -->
              </ul>
              <!-- <ul class="leftFloat">
                <li>
                  <span>备注:</span>
                  <span :title="asnMaster.memo">&nbsp;{{asnMaster.memo}}</span>
                </li>
                <li v-for="(item,index) in asnUdfHs" :key="index">
                  <span>{{item.value}}:</span>
                  <span :title="asnMaster[item.paramCode]">&nbsp;{{asnMaster[item.paramCode]}}</span>
                </li>
                <li>
                  <span>交货地址:</span>
                  <span :title="asnMaster.deliveryPlace">&nbsp;{{asnMaster.deliveryPlace}}</span>
                </li>
                <li>
                  <span>运输描述:</span>
                  <span :title="asnMaster.trafficDescribe">&nbsp;{{asnMaster.trafficDescribe}}</span>
                </li>
              </ul> -->
            </div>
          </el-tab-pane>
          <el-tab-pane v-if="asnMaster.insertType === 0" label="审核进程" name="reviewStep">
            <ReviewStepBox :masterData="asnMaster.billNo" :activeName="activeName"></ReviewStepBox>
          </el-tab-pane>
          <el-tab-pane label="订单进程" name="billRecord">
            <BillRecordBox :masterData="asnMaster.billNo" :activeName="activeName"></BillRecordBox>
          </el-tab-pane>
        </el-tabs>

        <!--<div class="billMasterBox clearFloat">
          <div class="billData leftFloat clearFloat">
            <div class="title">
              <span>入库单<span v-if="isFile">归档</span>单据信息</span>
            </div>
            <ul class="leftFloat">
              <li>
                <span>单号:</span>
                <span>&nbsp;{{asnMaster.billNo}}</span>
              </li>
              <li>
                <span>单据来源:</span>
                <span v-if="asnMaster.billSource === 0">采购单</span>
                <span v-else-if="asnMaster.billSource === 1">新建</span>
              </li>
              <li>
                <span>入库单类型:</span>
                <span v-for="item in asnTypes" v-if="asnMaster.asnType === item.dataDictDetailId">{{item.dictValue}}</span>
              </li>
              <li v-if="asnMaster.billSource === 0">
                <span>采购单号:</span>
                <span :title="asnMaster.requestBuyBillNo">&nbsp;
                  <span class="textBtn" @click="$router.push({name: 'buyBillInDetail',params: {billNo: asnMaster.requestBuyBillNo}})">{{asnMaster.requestBuyBillNo}}</span>
                </span>
              </li>
              <li v-if="asnMaster.insertType === 0">
                <span>审核状态:</span>&nbsp;
                <span v-if="asnMaster.state === -2">草稿</span>
                <span v-else-if="asnMaster.state === 2">待审核</span>
                <span v-else-if="asnMaster.state === 1">审核通过</span>
                <span v-else-if="asnMaster.state === 0">审核中</span>
                <span v-else-if="asnMaster.state === -1">审核退回</span>
              </li>
              <li>
                <span>保存类型:</span>&nbsp;
                <span v-if="asnMaster.insertType === 0">单据</span>
                <span v-else-if="asnMaster.insertType === -2">草稿</span>
              </li>
              <li>
                <span>备注:</span>
                <span>&nbsp;{{asnMaster.memo}}</span>
              </li>
              <li v-for="item in asnUdfHs">
                <span>{{item.value}}:</span>
                <span :title="asnMaster[item.paramCode]">&nbsp;{{asnMaster[item.paramCode]}}</span>
              </li>
            </ul>
            <ul class="rightFloat">
              <li>
                <span>仓库:</span>
                <span>&nbsp;
                  <span class="textBtn" @click="$parent.showPublicModal(asnMaster.wareId, 'ware')">{{asnMaster.wareName}}</span>
                </span>
              </li>
              <li>
                <span>工厂:</span>
                <span :title="asnMaster.organizationName">&nbsp;
                  <span class="textBtn" @click="$parent.showPublicModal(asnMaster.organizationId, 'organization')">{{asnMaster.organizationName}}</span>
                </span>
              </li>
              <li>
                <span>供应商:</span>
                <span :title="asnMaster.supplierName">&nbsp;
                  <span class="textBtn" @click="$parent.showPublicModal(asnMaster.supplierId, 'supplier')">{{asnMaster.supplierName}}</span>
                </span>
              </li>
              <li>
                <span>创建人:</span>
                <span :title="asnMaster.createUserName">&nbsp;
                  <span class="textBtn" @click="$parent.showPublicModal(asnMaster.userId, 'account')">{{asnMaster.createUserName}}</span>
                </span>
              </li>
              <li>
                <span>创建时间:</span>
                <span>&nbsp;{{asnMaster.createTime}}</span>
              </li>
              <li>
                <span>预期到货时间:</span>
                <span>&nbsp;{{asnMaster.expectTime}}</span>
              </li>
              <li>
                <span>发货地址:</span>
                <span>&nbsp;{{asnMaster.loadingPlace}}</span>
              </li>
              <li>
                <span>交货地址:</span>
                <span>&nbsp;{{asnMaster.deliveryPlace}}</span>
              </li>
              <li>
                <span>运输描述:</span>
                <span>&nbsp;{{asnMaster.trafficDescribe}}</span>
              </li>
            </ul>
          </div>
          <ReviewStepBox :masterData="asnMaster.billNo"></ReviewStepBox>
        </div>-->
      </div>
      <span class="moreSearchBtn" :class="isMoreSearchMaster ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
        @click="isMoreSearchMaster = !isMoreSearchMaster"></span>
    </div>

    <el-tabs type="card" v-model="activeName2" @tab-click="handleClick">
      <el-tab-pane label="单据详情" name="first">
        <div>
          <div style="font-size: 15px;margin-left:20px">
            总数量：{{ asnRecord.recordQuantityTotal }}
            总行数：{{ asnRecord.recordCount }}
          </div>
          <!-- {{ asnDetails }} -->
          <el-table ref="billDetail" border :header-cell-style="$parent.tableHeaderColor" :max-height="clientHeight - 380"
            stripe highlight-current-row @selection-change="handleSelectionChange" :data="asnRecord.data"
            style="width: 100%">
            <el-table-column type="selection" width="30">
            </el-table-column>
            <el-table-column fixed="left" type="index" width="30">
            </el-table-column>
            <!-- <el-table-column prop="allotDetailNo" label="allotDetailNo" fixed="left"  width="50">
            </el-table-column> -->
            <el-table-column show-overflow-tooltip prop="itemName" label=" 产品名称" min-width="180">
              <template slot-scope="scope">
                <div @click="$parent.showPublicModal(scope.row.itemCode, 'item')"
                  style="display: inline-block;margin-left:5px;  ">
                  <span class="textBtn" style="color:red">{{ scope.row.itemCode }}</span>
                  <br>
                  <span>{{ scope.row.itemDesc }}</span>
                  <br>
                  <span>{{ scope.row.model ? scope.row.model : '' }}{{ scope.row.spec ? scope.row.spec : '' }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="状态" min-width="80">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.acceptQuantity === 0" type="info">待验收</el-tag>
                <el-tag v-else-if="scope.row.acceptQuantity > 0 && scope.row.acceptQuantity < scope.row.expectQuantity">
                  部分验收
                </el-tag>
                <el-tag v-else-if="scope.row.acceptQuantity > 0 && scope.row.acceptQuantity === scope.row.expectQuantity"
                  type="success">全部验收
                </el-tag>
                <el-tag v-else-if="scope.row.acceptQuantity > 0 && scope.row.acceptQuantity > scope.row.expectQuantity"
                  type="danger">超额验收
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column label="管理方式" min-width="80">
              <template slot-scope="scope">
                <span v-for="(item, index) in itemAbc" :key="index" v-if="item.dictKey == scope.row.abc">{{ item.dictValue
                }}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="qcType" label="质检类型" min-width="80">
              <template slot-scope="scope">
                <span v-for="(item, index) in qcTypes" :key="index" v-if="item.dictKey == scope.row.qcType">{{
                  item.dictValue }}</span>
              </template>
            </el-table-column>
            <el-table-column label="跟踪方式" min-width="100">
              <template slot-scope="scope">
                <span v-for="(item, index) in traceMethods" :key="index" v-if="item.dictKey == scope.row.mt">{{
                  item.dictValue }}</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="batch" label="批次" min-width="120">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="serialNos" label="序列号" min-width="120">
              <template slot-scope="scope">
                <div v-for="serialNo in scope.row.serialNoList">
                  {{ serialNo.serialNo }}
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="poundState" label="称重状态" min-width="80">
              <template slot-scope="scope">
                <el-tag v-for="(item, index) in poundStates" :key="index" :type="item.type"
                  v-if="item.value == scope.row.poundState">{{ item.label }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="poundQuantity" label="磅重" min-width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.poundState">
                  {{ scope.row.poundQuantity ? (scope.row.poundQuantity / scope.row.transRatio) : 0 }}
                </span>
                <span style="font-size:15px" v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="cjQuantity" label="送检数" min-width="60">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="acceptQuantity" label="入库数" min-width="100">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="expectQuantity" label="采购数" min-width="100">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="packDescribe" label="单位" min-width="80">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="price" label="单价(元)" min-width="100">
            </el-table-column>
            <el-table-column show-overflow-tooltip fixed="right" prop="poundState" label="操作" min-width="80">
              <template slot-scope="scope">
                <el-button
                  v-if="asnMaster.insertType === 0 && asnMaster.asnState !== 3 || asnMaster.insertType === 0 && asnMaster.asnState !== 5 || asnMaster.insertType === 0 && asnMaster.asnState !== 4"
                  @click="showAcceptModal(scope.row)" icon="iconfont icon-shouhuo" type="success" circle plain
                  size="small"></el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="磅单记录" name="second">
        <div>
          <div style="font-size: 15px;margin-left:20px">
            总数量：{{ poundRecord.recordQuantityTotal }}
            总行数：{{ poundRecord.recordCount }}
          </div>
          <el-table border :header-cell-style="$parent.tableHeaderColor" :max-height="clientHeight - 380" stripe
            highlight-current-row :data="poundRecord.data" style="width: 100%">
            <el-table-column show-overflow-tooltip prop="itemName" label="产品编码/名称" min-width="100">
              <template slot-scope="scope">
                <div @click="$parent.showPublicModal(scope.row.itemCode, 'item')"
                  style="display: inline-block;margin-left:5px;  ">
                  <span class="textBtn" style="color:red">{{ scope.row.itemCode }}</span>
                  <br>
                  <span>{{ scope.row.itemName }}</span>
                  <br>
                  <span>{{ scope.row.model ? scope.row.model : '' }}{{ scope.row.spec ? scope.row.spec : '' }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="driverName" label="司机" min-width="120">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.carNo }}</span>
                  <br>
                  <span>{{ scope.row.driverName }}</span>
                  <br>
                  <span>{{ scope.row.driverPhone }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="poundState" label="称重状态" min-width="80">
              <template slot-scope="scope">
                <el-tag v-for="(item, index) in poundStates" :key="index" :type="item.type"
                  v-if="item.value == scope.row.poundState">{{ item.label }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="grossWeight" label="毛重" min-width="70">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.grossWeight }}</span>
                  <br>
                  <span>{{ scope.row.reGrossWeight }}*</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="wtImgGross" label="毛重照片" min-width="200">
              <template slot-scope="scope">
                <div style="width:100%">
                  <div v-for="(item, index) in scope.row.imgGross" :key="index" style="width:60px;float:left">
                    <el-image style="width: 50px; height: 50px" :src="item" :preview-src-list="scope.row.imgGross">
                    </el-image>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="tareWeight" label="皮重" min-width="70">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.tareWeight }}</span>
                  <br>
                  <span>{{ scope.row.reTareWeight }}*</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="wtImgGross" label="皮重照片" min-width="200">
              <template slot-scope="scope">
                <div style="width:100%">
                  <div v-for="(item, index) in scope.row.imgTare" :key="index" style="width:60px;float:left">
                    <el-image style="width: 50px; height: 50px" :src="item" :preview-src-list="scope.row.imgTare">
                    </el-image>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="netWeight" label="净重" min-width="80">
              <template slot-scope="scope">
                <span>{{ (scope.row.poundQuantity) }} </span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="packDescribe" label="单位" min-width="60">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="grossWeightTime" label="时间" min-width="130">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.grossWeightTime }}</span>
                  <br>
                  <span>{{ scope.row.tareWeightTime }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="billNo" label="单号" min-width="100">
              <template slot-scope="scope">
                <div>
                  <span>{{ scope.row.billNo }}</span>
                  <br>
                  <span>{{ scope.row.sendNo }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="poundState" fixed="right" label="操作" min-width="180">
              <template slot-scope="scope">
                <el-button
                  v-if="(scope.row.poundState >= 3 || (scope.row.poundState > 0 && scope.row.poundIda && scope.row.poundIdb)) && scope.row.poundState < 9"
                  type="warning" size="small" @click="updatePoundState(scope.row)">确认磅单</el-button>
                <el-button v-if="scope.row.poundState > 0" type="primary" size="small"
                  @click="printPoundBill(scope.row)">打印磅单</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="送检记录" name="third">
        <div>
          <div style="font-size: 15px;margin-left:20px">
            总数量：{{ qcRecord.recordQuantityTotal }}
            总行数：{{ qcRecord.recordCount }}
          </div>
          <el-table border :header-cell-style="$parent.tableHeaderColor" :max-height="clientHeight - 380" stripe
            highlight-current-row :data="qcRecord.data" style="width: 100%">
            <el-table-column show-overflow-tooltip prop="itemName" label="产品编码/产品名称" min-width="180">
              <template slot-scope="scope">
                <div @click="$parent.showPublicModal(scope.row.itemCode, 'item')"
                  style="display: inline-block;margin-left:5px;  ">
                  <span class="textBtn" style="color:red">{{ scope.row.itemCode }}</span>
                  <br>
                  <span>{{ scope.row.itemName }}</span>
                  <br>
                  <span>{{ scope.row.spec }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="wareCode" label="仓库" min-width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.wareCode }} </span>
                <br />
                <span>{{ scope.row.wareName }} </span>
              </template>
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip prop="batchName" label="批次" min-width="120">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="state" label="状态" min-width="100">
              <template slot-scope="scope">
                <span v-for="(item, index) in qcAllResult " :style="item.style" v-if="scope.row.state == item.value">{{
                  item.label }} </span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="quality" label="浓度(%)" min-width="80">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="memo" label="备注" min-width="120">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="createTime" label="送检时间" min-width="110">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="createUserName" label="送检人" min-width="110">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="modifyTime" label="检验时间" min-width="110">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="modifyUserName" label="检验人" min-width="110">
            </el-table-column>
            <!-- <el-table-column show-overflow-tooltip prop="boxCode" label="跟踪号" min-width="110">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="cellCode" label="货位号" min-width="110">
            </el-table-column> -->
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="验收记录" name="fourth">
        <div>
          <div style="font-size: 15px;margin-left:20px">
            总数量：{{ acceptRecord.recordQuantityTotal }}
            总行数：{{ acceptRecord.recordCount }}
          </div>
          <el-table border :header-cell-style="$parent.tableHeaderColor" :max-height="clientHeight - 380" stripe
            highlight-current-row :data="acceptRecord.data" style="width: 100%">
            <el-table-column show-overflow-tooltip prop="itemName" label="缩略图/产品编码/产品名称" min-width="200">
              <template slot-scope="scope">

                <div @click="$parent.showPublicModal(scope.row.itemCode, 'item')"
                  style="display: inline-block;margin-left:5px;  ">
                  <span class="textBtn" style="color:red">{{ scope.row.itemCode }}</span>
                  <br>
                  <span>{{ scope.row.itemName }}</span>
                  <br>
                  <span>{{ scope.row.spec }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="wareCode" label="仓库" min-width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.wareCode }} </span>
                <br />
                <span>{{ scope.row.wareName }} </span>
              </template>
            </el-table-column>
            <el-table-column sortable show-overflow-tooltip prop="batchName" label="批次" min-width="80">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="pickQuantity" label="序列号" min-width="100">
              <template slot-scope="scope">
                <div v-for="serialNo in scope.row.serialNoList">{{ serialNo.serialNo }}</div>
              </template>
            </el-table-column>

            <el-table-column show-overflow-tooltip prop="quantity" label="验收数量" min-width="100">
              <template slot-scope="scope">
                <span>{{ scope.row.quantity }} </span>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="packDescribe" label="单位" min-width="80">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="acceptTime" label="验收时间" min-width="110">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="acceptUserName" label="验收人" min-width="110">
            </el-table-column>
            <!-- <el-table-column show-overflow-tooltip prop="boxCode" label="跟踪号" min-width="110">
            </el-table-column>
            <el-table-column show-overflow-tooltip prop="cellCode" label="货位号" min-width="110">
            </el-table-column> -->
          </el-table>
        </div>
      </el-tab-pane>

    </el-tabs>

    <div style="display: none;">
      <div id="printPoundBill" style="width:900px; ">
        <div style="text-align:center;font-weight:bold;font-size: 24px;margin-top: 10px;">{{ companyName }}过磅单</div>
        <div style="margin-top:10px">
          <div style="width:33%;float: left;">工厂：{{ asnMaster.organizationName }}</div>
          <div style="width:33%;float: left;">单据编号：{{ asnMaster.billNo }}</div>
          <div style="width:33%;float: left;">制单时间：{{ asnMaster.createTime }}</div>
        </div>
        <table style="width:900px;border-collapse: collapse;margin-top: 10px; text-align: center; line-height: 30px;"
          border="1">
          <tr>
            <td style="width: 150px;">车号</td>
            <td style="width: 300px;">{{ printAsnBillObj.carNo }}</td>
            <td style="width: 150px;">毛重</td>
            <td style="width: 300px;">{{
              printAsnBillObj.reGrossWeight ? printAsnBillObj.reGrossWeight + "*" : printAsnBillObj.grossWeight }}</td>
          </tr>
          <tr>
            <td style="width: 150px;">供货单位</td>
            <td style="width: 300px;">{{ asnMaster.supplierName }}</td>
            <td style="width: 150px;">皮重</td>
            <td style="width: 300px;">{{
              printAsnBillObj.reTareWeight ? printAsnBillObj.reTareWeight + "*" : printAsnBillObj.tareWeight }}</td>
          </tr>
          <tr>
            <td style="width: 150px;">驾驶员</td>
            <td style="width: 300px;">{{ printAsnBillObj.driverName }}</td>
            <td style="width: 150px;">净重</td>
            <td style="width: 300px;">{{ (printAsnBillObj.poundQuantity) }}</td>
          </tr>
          <tr>
            <td style="width: 150px;">货物名称</td>
            <td style="width: 300px;">{{ printAsnBillObj.itemDesc }}</td>
            <td style="width: 150px;">进厂时间</td>
            <td style="width: 300px;">{{ printAsnBillObj.grossWeightTime }}</td>
          </tr>
          <tr>
            <td style="width: 150px;">备注</td>
            <td style="width: 300px;">{{ printAsnBillObj.memo }}</td>
            <td style="width: 150px;">结算重量</td>
            <td style="width: 300px;">{{
              (printAsnBillObj.reGrossWeight ? printAsnBillObj.reGrossWeight : printAsnBillObj.grossWeight) -
              (printAsnBillObj.reTareWeight ? printAsnBillObj.reTareWeight : printAsnBillObj.tareWeight) }}</td>
          </tr>
        </table>

        <div style="margin-top:10px">
          <div style="width:33%;float: left;">司磅员：</div>
          <div style="width:33%;float: left;">客户签名：</div>
          <div style="width:33%;float: left;">打印时间：{{ new Date().toLocaleString() }}</div>
        </div>
      </div>
    </div>

    <div style="display: none;">
      <div id="printAsnBill" style="width:900px; ">
        <div style="text-align:center;font-weight:bold;font-size: 24px;margin-top: 10px;">{{ companyName }}材料入库单</div>
        <div style="margin-top:10px">
          <div style="width:33%;float: left;">单据编号：{{ asnMaster.billNo }}</div>
          <div style="width:66%;float: left;">制单时间：{{ asnMaster.createTime }}</div>
        </div>
        <div style="margin-top:10px">
          <div style="width:33%;float: left;">工厂：{{ asnMaster.organizationName }}</div>
          <div style="width:66%;float: left;">供应商：{{ asnMaster.supplierName }}</div>
        </div>
        <table style="width:760px;border-collapse: collapse;margin-top: 10px;" border="1">
          <tr>
            <th>物料编码</th>
            <th>物料名称</th>
            <th>型号规格</th>
            <th>主单位</th>
            <th>应收数量</th>
            <th>实收数量</th>
            <th>单价</th>
            <th>金额</th>
            <th>库房</th>
            <th>行备注</th>
          </tr>
          <tr v-bind:key="asnDetail.asnDetailId" v-for="asnDetail in multipleSelection">
            <td>{{ asnDetail.itemCode }}</td>
            <td>{{ asnDetail.itemName }}</td>
            <td>{{ asnDetail.model ? asnDetail.model : '' }}{{ asnDetail.spec ? asnDetail.spec : '' }}</td>
            <td>{{ asnDetail.packDescribe }}</td>
            <td>{{ asnDetail.expectQuantity }}</td>
            <td>{{ asnDetail.acceptQuantity }}</td>
            <td>{{ asnDetail.price }}</td>
            <td>{{ asnDetail.price * asnDetail.acceptQuantity }}</td>
            <td> {{ asnDetail.wareCode }}-{{ asnDetail.wareName }}</td>
            <td> {{ asnDetail.memo }}</td>
          </tr>
        </table>

        <div style="margin-top:10px">
          <div style="width:33%;float: left;">制单人：{{ }}</div>
          <div style="width:33%;float: left;">库管员：{{ }}</div>
          <div style="width:33%;float: left;">采购人：{{ }}</div>
        </div>
        <div style="margin-top:10px">
          <div style="width:33%;float: left;">第一联：库房留存</div>
          <div style="width:33%;float: left;">第二联：财务核算</div>
          <div style="width:33%;float: left;">第三联：经办人留存</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState
} from 'vuex'
import ReviewStepBox from '@/components/ReviewStepBox'
import BillRecordBox from '@/components/BillRecordBox'

export default {
  name: 'AsnBillDetail',
  components: {
    ReviewStepBox,
    BillRecordBox,
  },
  data() {
    return {
      organizationId: PF.getLocal('organizationId'),
      organizationCode: PF.getLocal('organizationCode'),
      organizationName: PF.getLocal('organizationName'),

      typeKey: 'deviceCapDirKey',
      upUrl: BASEIP + '/upload/files',
      rtUrl: BASEIP + "/file/files",
      rlPath: '',

      companyName: '',

      asnMasterId: this.$route.query.asnMasterId,
      billNo: this.$route.query.billNo,
      clientHeight: $(window).height(),
      printAsnBillObj: {
        carNo: null,
      },
      wares: [],
      loading: false,
      isMoreSearch: false,
      isMoreSearchMaster: true,
      isMoreSearch2: false,
      isMoreSearch3: false,
      activeName: 'first',
      activeName2: 'first',

      searchData: {

      },
      searchDataItem: {
        pageNum: 1,
        pageSize: 5,
        total: null,
        keyWords: '',
        wareId: null,
        organizationId: null,
      },
      searchSelectCell: {
        pageNum: 1,
        pageSize: 5,
        total: null,
        keyWords: '',
        wareId: '',
        areaId: '',
        cellUseType: 0,
      },

      asnMaster: {},
      asnDetails: [],
      asnShipDetails: [],
      asnRecord: {
        recordQuantityTotal: 0,
        recordCount: 0,
        data: [],
      },
      poundRecord: {
        recordQuantityTotal: 0,
        recordCount: 0,
        data: [],
      },
      acceptRecord: {
        recordQuantityTotal: 0,
        recordCount: 0,
        data: [],
      },
      qcRecord: {
        recordQuantityTotal: 0,
        recordCount: 0,
        data: [],
      },
      exDetails: [],
      form: {
        asnMaster: {},
        asnDetails: [],
      },
      asnUdfHs: [],
      asnUdfDs: [],
      // asnTypes: [],
      ids: [],
      items: [],
      suppliers: [],
      organizations: [],
      platforms: [],
      carriers: [],
      multipleSelection: [],
      // multipleSelection2: [],
      qcUdfHs: [],
      qcUdfDs: [],

      batchTactics: [],
      cells: [],
      areas: [],
      packDetails: [],
      acceptExcess: null,

      innerVisibleItem: false,
      dialogNewVisible: false,
      dialogQcVisible: false,
      dialogReviewVisible: false,
      dialogAcceptVisible: false,
      innerVisibleCell: false,

      rules: {
        supplierId: [{
          required: true,
          message: '请选择工厂',
          trigger: 'blur'
        }],
        buyType: [{
          required: true,
          message: '请选择供应商',
          trigger: 'blur'
        }],
      },

      formLabelWidth: '120px',
      detailQuantityTotal: 0,
      detailAcceptQuantityTotal: 0,
      detailCount: 0,
      recordQuantityTotal: 0,
      recordCount: 0,

      formQc: {
        billSource: 0,
        insertType: -2,
        qcMaster: {},
        qcDetails: [],
      },
      rulesQc: {
        billNo: [{
          required: true,
          message: '请输入单号',
          trigger: 'blur'
        }],
        organizationId: [{
          required: true,
          message: '请选择供应商',
          trigger: 'blur'
        }],
        supplierId: [{
          required: true,
          message: '请选择工厂',
          trigger: 'blur'
        }],
        qcType: [{
          required: true,
          message: '请选择质检类型',
          trigger: 'blur'
        }],
      },

      formReview: {
        billNo: this.$route.params.billNo,
        reviewResult: null,
        caseDetailId: null,
        memo: '',
      },
      rulesReview: {
        memo: [{
          required: true,
          message: '请填写审核原因',
          trigger: 'blur'
        }],
      },
      asnPrintData: {},

      formAccept: {
        asnBillNo: '',
        asnDetailNo: '',
        wareId: null,
        wareName: '',
        organizationId: null,
        organizationName: '',
        toPackDetailId: null,
        toTransRatio: null,
        toPackDescribe: '',
        itemCode: '',
        itemName: '',
        packCode: '',
        toCellCode: '',
        toBoxCode: '',
        toQuantity: 0,
        billType: 2,
        expectQuantity: 0,
        acceptQuantity: 0,
        'productDate': '',
        'exDate': '',
        'inDate': '',
        'supplierCode': '',
        'supplierBatch': '',
        'qcState': '',
        'costPrice': null,
        'salePrice': '',
        'detailNo': '',
        'memo1': '',
        'memo2': '',
        'memo3': '',
        'batchAttribute1': '',
        'batchAttribute2': ''
      },
      rulesAccept: {
        wareId: [{
          required: true,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        organizationId: [{
          required: true,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        itemName: [{
          required: true,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        itemCode: [{
          required: true,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        toPackDetailId: [{
          required: true,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        toQuantity: [{
          required: true,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        toCellCode: [{
          required: true,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'productDate': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'exDate': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'inDate': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'supplierCode': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'supplierBatch': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'qcState': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'costPrice': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'salePrice': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'detailNo': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'memo1': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'memo2': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'memo3': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'batchAttribute1': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
        'batchAttribute2': [{
          required: false,
          message: '必填项不得为空',
          trigger: 'blur'
        }],
      },

    }
  },
  computed: {
    ...mapState([
      "cellUseTypes",
      "cellAbcLevels",
      "cellPickTypes",
      "asnTypes",
      "abcLevels", "qcStates", "itemAbc", "traceMethods", "qcTypes", "qcAllResult", "poundStates"
    ]),
    supplierId() {
      return this.form.asnMaster.supplierId
    },
    isFile() {
      if (this.$route.params.fileUserId) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    supplierId(val) {
      for (let i = 0; i < this.suppliers.length; i++) {
        if (this.suppliers[i].supplierId === val) {
          this.form.asnMaster.supplierName = this.suppliers[i].supplierName;
        }
      }
    },
    asnDetails(val) {
      let pd = [], asn = [];
      var poundtotal = 0;
      var asntotal = 0;
      val.forEach(v => {
        v.imgGross = this.getImageList(v.inImgGross).concat(this.getImageList(v.wtImgGross));
        v.imgTare = this.getImageList(v.inImgTare).concat(this.getImageList(v.wtImgTare));
        if (v.poundState > 0) {
          v.poundQuantity = (v.reGrossWeight ? v.reGrossWeight : v.grossWeight) - (v.reTareWeight ? v.reTareWeight : v.tareWeight);
          pd.push(v);
          poundtotal += v.expectQuantity;
        }
        asn.push(v);
        asntotal += v.expectQuantity;
      });
      this.poundRecord.data = pd;
      this.poundRecord.recordCount = pd.length;
      this.poundRecord.recordQuantityTotal = poundtotal;

      this.asnRecord.data = asn;
      this.asnRecord.recordCount = asn.length;
      this.asnRecord.recordQuantityTotal = asntotal;
    },
  },
  methods: {
    getImageList(wtImg) {
      let fileList = [];
      if (wtImg) {
        let data = wtImg.split(',');
        //每循环一次就把后台返回的图片地址添加到filesList数组	
        for (let d of data) {
          fileList.push(this.rtUrl + this.rlPath + d);
        }
      }
      return fileList;
    },
    printAsnBill() {
      if (this.multipleSelection && this.multipleSelection.length > 0) {
        if ($("#printAsnBill")) $("#printAsnBill").printArea();
      } else {
        IOT.tips("请选择需要打印的入库单数据", "error");
      }
    },
    updatePoundState(row) {
      IOT.showOverlay("正在更新，请稍等...");
      IOT.getServerData('/asn/details/updatePound', 'post', row, (ret) => {
        if (ret.code === 200) {
          row.poundState = 9;
          IOT.hideOverlay();
          IOT.tips("磅单确认成功", "success");
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      }, true)
    },
    printPoundBill(row) {
      this.printAsnBillObj = row;
      this.$nextTick(() => {
        var htmlStr = $('#printPoundBill').prop('outerHTML');
        this.$parent.printHtml(htmlStr, 1);
      });
      // setTimeout(() => {
      //   // var htmlStr = $('#printPoundBill').prop('outerHTML');        
      //   // this.$parent.printHtml(htmlStr, 1);
      //   // $("#printPoundBill").innerHTML;
      //   if($("#printPoundBill"))$("#printPoundBill").printArea();
      // }, 200);
    },
    //退货
    createOut() {
      var that = this;
      var params = {
        asnMaster: that.asnMaster,
        asnDetails: that.acceptRecord.data
      };
      this.$router.push({
        name: 'createSoBillFromIn',
        params: params,
      })
    },
    exportAns() {
      let url = '/asn/masters/daochu';
      IOT.showOverlay("正在导出，请稍等...");
      IOT.getServerData(url, 'get', {
        asnMasterId: this.asnMaster.asnMasterId
      }, (ret) => {
        if (ret.code === 200) {
          if (ret.code === 200) {
            var path = ret.data;
            if (path != undefined && path != null) {
              window.location.href = path;
            }

            //   window.open(path);
          } else {
            IOT.tips(
              ret.message || "服务器请求失败，稍后再试！",
              "error"
            );
          }
          IOT.hideOverlay();
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      }, true);
    },
    handleClick(tab, event) {
      this.$nextTick(() => {
        if (this.$refs.billDetail && this.$refs.billDetail.doLayout) {
          this.$refs.billDetail.doLayout();
        }
      })
    },
    handleCurrentChange2(val) {
      this.pageNum = val;
      this.getItemData()
    },
    handleCurrentChange3(val) {
      this.searchSelectCell.pageNum = val;
      this.getCellData()
    },
    clearSearchSelectItem() {
      this.searchDataItem.keyWords = '';
      this.searchDataItem.wareId = null;
      this.searchDataItem.organizationId = null;
    },
    clearSearchSelectCell() {
      this.searchSelectCell.keyWords = '';
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // printList() {
    //   this.$router.push({
    //     name: 'printView',
    //     params: {
    //       printList: this.multipleSelection
    //     }
    //   });
    // },
    getAsnMasterData() {
      this.loading = true;
      let url = '';
      if (this.isFile) {
        url = '/asn/master/files/list';
      } else {
        url = '/asn/masters/list';
      }
      IOT.getServerData(url, 'get', {
        asnMasterId: this.asnMasterId
      }, (ret) => {
        this.loading = false;
        if (ret.code === 200) {
          let list = ret.rows[0];
          if (PF.isNull(list.createTime)) {
            list.createTime1 = list.createTime.substr(5, 11);
          }
          if (PF.isNull(list.expectTime)) {
            list.expectTime1 = list.expectTime.substr(5, 11);
          }
          this.asnMaster = list;

          this.getWares()

          this.getWares();

          this.getWares();
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getAsnDetailData() {
      var that = this;
      this.loading = true;
      let url = '';
      if (this.isFile) {
        url = '/asn/detail/files/list';
      } else {
        url = '/asn/details/findByBillNo';
      }
      IOT.getServerData(url, 'get', { asnMasterId: this.asnMasterId }, (ret) => {
        this.loading = false;
        if (ret.code === 200) {
          let list = ret.rows;
          let detailQuantityTotal = 0;
          let detailAcceptQuantityTotal = 0;
          let detailCount = list.length;
          for (let i = 0; i < list.length; i++) {
            list[i].packExpectQuantity = list[i].expectQuantity / list[i].transRatio;
            list[i].packAcceptQuantity = list[i].acceptQuantity / list[i].transRatio;
            detailQuantityTotal += list[i].packExpectQuantity;
            detailAcceptQuantityTotal += list[i].packAcceptQuantity;
          }
          that.detailQuantityTotal = detailQuantityTotal;
          that.detailAcceptQuantityTotal = detailAcceptQuantityTotal;
          that.detailCount = detailCount;
          // that.asnDetails = ret.rows;
          that.asnDetails = list;
          // for (let i = 0; i < that.asnDetails.length; i++) {
          //   that.getSerialNo(that.asnDetails[i].asnDetailId);

          // }

          that.searchData.total = ret.total;
          that.searchData.pageNum = ret.pageNumber;
          this.$nextTick(() => {
            if (this.$refs.billDetail && this.$refs.billDetail.doLayout) {
              this.$refs.billDetail.doLayout();
            }
          })
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getSerialNo(asnDetailId) {
      let url = '/serial/nos/list';
      IOT.getServerData(url, 'get', {
        wmsId: asnDetailId
        , type: 1
      }, (ret) => {
        if (ret.code === 200) {
          var serialNos = ret.rows;
          for (var i = 0; i < this.asnDetails.length; i++) {
            if (this.asnDetails[i].asnDetailId == asnDetailId) {
              this.asnDetails[i].serialNos = serialNos;
              this.$nextTick(() => {
                if (this.$refs.billDetail && this.$refs.billDetail.doLayout) {
                  this.$refs.billDetail.doLayout();
                }
              })
            }
          }
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getAcceptRecordData() {
      var that = this;
      that.acceptRecord = {
        recordCount: 0,
        recordQuantityTotal: 0,
        data: [],
      }
      IOT.getServerData('/accept/records/list', 'get', {
        asnMasterId: this.asnMasterId
      }, (ret) => {

        if (ret.code === 200) {
          var recordQuantityTotal = 0;

          ret.rows.forEach(row => {
            recordQuantityTotal += row.quantity;
          });

          that.acceptRecord.recordCount = ret.rows.length;
          that.acceptRecord.recordQuantityTotal = recordQuantityTotal;
          that.acceptRecord.data = ret.rows;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getItemData() {
      IOT.getServerData('/item/infos/list', 'get', this.searchDataItem, (ret) => {
        if (ret.code === 200) {
          this.items = ret.rows;
          this.searchDataItem.total = ret.total;
          this.searchDataItem.pageNum = ret.pageNumber
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getQcRecordData() {
      var that = this;
      that.qcRecord = {
        recordCount: 0,
        recordQuantityTotal: 0,
        data: [],
      }
      IOT.getServerData('/qc/infos/list', 'get', {
        asnMasterId: this.asnMasterId
      }, (ret) => {

        if (ret.code === 200) {
          var recordQuantityTotal = 0;

          ret.rows.forEach(row => {
            recordQuantityTotal += row.quantity;
          });

          that.qcRecord.recordCount = ret.rows.length;
          that.qcRecord.recordQuantityTotal = recordQuantityTotal;
          that.qcRecord.data = ret.rows;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getItemData() {
      IOT.getServerData('/item/infos/list', 'get', this.searchDataItem, (ret) => {
        if (ret.code === 200) {
          this.items = ret.rows;
          this.searchDataItem.total = ret.total;
          this.searchDataItem.pageNum = ret.pageNumber
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getPlatformData() {
      IOT.getServerData('/platforms/list', 'get', {
        wareId: this.asnMaster.wareId
      }, (ret) => {
        if (ret.code === 200) {
          let list = ret.rows;
          for (let i = 0; i < list.length; i++) {
            list[i].label = list[i].platformCode
          }
          this.platforms = list;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getCarrierData() {
      IOT.getServerData('/carriers/list', 'get', {}, (ret) => {
        if (ret.code === 200) {
          this.carriers = ret.rows;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getCellData() {
      IOT.getServerData('/cell/infos/list', 'get', this.searchSelectCell, (ret) => {
        if (ret.code === 200) {

          this.cells = ret.rows;
          this.searchSelectCell.total = ret.total;
          this.searchSelectCell.pageNum = ret.pageNumber
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getAreaData() {
      IOT.getServerData('/area/infos/list', 'get', {
        wareId: this.searchSelectCell.wareId
      }, (ret) => {
        if (ret.code === 200) {
          this.areas = ret.rows;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getPackDetailData(packCode) {
      IOT.getServerData('/pack/details/findByPackCode', 'get', {
        packCode: packCode
      }, (ret) => {
        if (ret.code === 200) {
          this.packDetails = ret.rows;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getQcInfos() {
      let that = this;
      that.loading.masterTable = true;
      IOT.getServerData('/qc/infos/list', 'get', this.searchData, (ret) => {
        that.loading.masterTable = false;
        if (ret.code === 200) {
          let list = ret.rows;
          this.qcRecords = list;
          this.searchData.total = ret.total;
          this.searchData.pageNum = ret.pageNumber;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    handleClose(done) {
      this.dialogNewVisible = false;
    },
    handleClose2(done) {
      this.formQc.qcDetails = [];
      this.dialogQcVisible = false;
    },
    handleClose3(done) {
      this.dialogReviewVisible = false;
    },
    handleClose4(done) {
      this.resetForm('formAccept');
      this.dialogAcceptVisible = false;
    },
    isDelete(id) {
      this.$confirm('确认删除？')
        .then(_ => {
          this.deleteBuyBillIn(id)
        })
        .catch(_ => { });
    },
    deleteBuyBillIn(id) {
      IOT.showOverlay('删除中，请稍等...');
      IOT.getServerData('/asn/masters/delete', 'get', {
        id: id
      }, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
          this.$router.go(-1);
          IOT.tips('删除成功！', 'success');
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    showUpdateModal() {
      this.form = {
        insertType: -2,
        asnMaster: PF.JSON(this.asnMaster),
        asnDetails: PF.JSON(this.asnDetails)
      };
      PF.getOrganizations((rows) => {
        this.organizations = rows;
      });
      this.getPlatformData();
      this.getCarrierData();
      this.dialogNewVisible = true;
    },
    showSelectItemModal() {
      this.ids = [];
      for (let i = 0; i < this.form.asnDetails.length; i++) {
        this.ids.push(this.form.asnDetails[i].itemCode)
      }
      this.searchDataItem.wareId = PF.getLocal('wareId', 'number');
      this.searchDataItem.organizationId = this.form.asnMaster.organizationId;
      this.getItemData();
      this.innerVisibleItem = true
    },
    selectItem(row) {
      if (this.ids.indexOf(row.itemCode) !== -1) {
        IOT.tips('相同物料不能重复添加！', 'error');
        this.innerVisibleItem = false;
        return false
      }
      this.ids.push(row.itemCode);
      IOT.getServerData('/pack/details/list', 'get', {
        packCode: row.packCode
      }, (ret) => {
        if (ret.code === 200) {
          let list = ret.rows;
          row.packDetails = [];
          for (let i = 0; i < list.length; i++) {
            row.packDetails.push({
              packDetailId1: list[i].packDetailId,
              packDescribe1: list[i].packDescribe,
              transRatio: list[i].transRatio
            })
          }
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      }, false, false);
      this.form.asnDetails.push(row);
      this.form.asnDetails.expectQuantity = 0;
      this.innerVisibleItem = false;
    },
    deleteDetail(index) {
      this.form.asnDetails.splice(index, 1)
    },
    updateForm() {
      let that = this;
      let form = this.form;
      if (form.asnDetails.length <= 0) {
        IOT.tips('未选择物料,请重新选择！', 'error', 1000, () => {
          IOT.hideOverlay();
        });
        return false
      }
      delete form.asnMaster.createTime;
      for (let i = 0; i < form.asnDetails.length; i++) {
        if (form.asnDetails[i].expectQuantity === 0 || !PF.isNull(form.asnDetails[i].expectQuantity)) {
          IOT.tips('数量不得为零,请重新输入！', 'error', 1000, () => {
            IOT.hideOverlay();
          });
          return false
        }
        // 主单位数量
        form.asnDetails[i].expectQuantity = form.asnDetails[i].expectQuantity * form.asnDetails[i].transRatio;
      }
      IOT.getServerData('/asn/masters/update', 'post', form, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
          IOT.tips('保存成功！', 'success', 1000, () => {
            that.getAsnMasterData();
            that.getAsnDetailData();
          });
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
      this.dialogNewVisible = false
    },
    isCommit() {
      this.$confirm('确认提交？')
        .then(_ => {
          this.commitForm()
        })
        .catch(_ => { });
    },
    commitForm() {
      IOT.showOverlay('保存中，请稍等...');
      let form = {
        billSource: this.asnMaster.billSource,
        insertType: 0,
        asnMaster: PF.JSON(this.asnMaster),
        asnDetails: PF.JSON(this.asnDetails)
      };
      if (form.asnDetails.length <= 0) {
        IOT.tips('未选择物料,请重新选择！', 'error', 1000, () => {
          IOT.hideOverlay();
        });
        return false
      }
      for (let i = 0; i < form.asnDetails.length; i++) {
        if (form.asnDetails[i].expectQuantity === 0) {
          IOT.tips('数量不得为零,请重新输入！', 'error', 1000, () => {
            IOT.hideOverlay();
          });
          return false
        }
        // 主单位数量
        form.asnDetails[i].expectQuantity = form.asnDetails[i].expectQuantity * form.asnDetails[i].transRatio;
      }
      IOT.getServerData('/asn/masters/insert', 'post', form, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
          this.$router.push({
            name: 'asnBill'
          });
          IOT.tips('保存成功！', 'success');
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    // getCreateList(data, callback) {
    //   IOT.getServerData('/qc/details/getCreateList', 'get', {
    //     asnBillNo: data.billNo,
    //     asnType: data.asnType
    //   }, (ret) => {
    //     IOT.hideOverlay();
    //     if (ret.code === 200) {
    //       callback(ret.rows)
    //     } else {
    //       IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
    //     }
    //   }, false, false);
    // },
    showQcModal() {
      let formQc = {
        billSource: 0,
        insertType: 0,
        qcMaster: PF.JSON(this.asnMaster),
        qcDetails: this.asnDetails
      };

      for (let i = 0; i < formQc.qcDetails.length; i++) {
        formQc.qcDetails[i].batchName = formQc.qcDetails[i].batch;

        // delete formQc.qcDetails[i].billNo; 
      }

      this.formQc = formQc;

      this.dialogQcVisible = true;
    },
    changePackDetailId(index, type) {
      if (type === 'qc') {
        let detail = PF.JSON(this.formQc.qcDetails[index]);
        for (let i = 0; i < detail.packDetailsList.length; i++) {
          if (detail.packDetailsList[i].packDetailId === detail.packDetailId) {
            this.formQc.qcDetails[index].packDescribe = detail.packDetailsList[i].packDescribe;
            this.formQc.qcDetails[index].transRatio = detail.packDetailsList[i].transRatio;
          }
        }
        // this.formQc.qcDetails[index].expectQuantity = PF.changePToChangeQ(this.formQc.qcDetails[index]);
      } else if (type === 'update') {
        let detail = PF.JSON(this.form.asnDetails[index]);
        for (let i = 0; i < detail.packDetails.length; i++) {
          if (detail.packDetails[i].packDetailId1 === detail.packDetailId) {
            this.form.asnDetails[index].packDescribe = detail.packDetails[i].packDescribe1;
            this.form.asnDetails[index].transRatio = detail.packDetails[i].transRatio1;
          }
        }
        // this.form.asnDetails[index].expectQuantity = PF.changePToChangeQ(this.form.asnDetails[index]);
      } else if (type === 'accept') {
        for (let i = 0; i < this.packDetails.length; i++) {
          if (this.packDetails[i].packDetailId === this.formAccept.toPackDetailId) {
            this.formAccept.toPackDescribe = this.packDetails[i].packDescribe;
            this.formAccept.toTransRatio = this.packDetails[i].transRatio;
          }
        }
        // this.form.asnDetails[index].expectQuantity = PF.changePToChangeQ(this.form.asnDetails[index]);
      }
    },
    resetForm(formName) { // 重置
      this.$refs[formName].resetFields();
    },
    commitFormQc(formName, insertType) {
      IOT.showOverlay('保存中，请稍等...');
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formQc = this.formQc;
          formQc.insertType = insertType;
          formQc.asnMasterId = this.asnMaster.asnMasterId
          // formQc.qcDetailList = PF.JSON(formQc.qcDetails);
          // delete formQc.qcDetails;
          IOT.getServerData('/qc/masters/insert', 'post', formQc, (ret) => {
            IOT.hideOverlay();
            if (ret.code === 200) {
              this.$router.push({
                name: 'asnBill'
              });
              IOT.tips('保存成功！', 'success');
            } else {
              IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
            }
          });
          this.resetForm(formName);
          this.handleClose2();
        } else {
          IOT.hideOverlay();
          console.error('error submit!!');
          return false;
        }
      });
    },
    commitReview(formName, reviewResult) {
      IOT.showOverlay('保存中，请稍等...');
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formReview = PF.JSON(this.formReview);
          formReview.reviewResult = reviewResult;
          IOT.getServerData('/asn/masters/review', 'get', formReview, (ret) => {
            IOT.hideOverlay();
            if (ret.code === 200) {
              IOT.tips('审核成功！', 'success');
              this.getCaseNowList();
              this.dialogReviewVisible = false;
            } else {
              IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
            }
          });
          this.resetForm(formName);
          this.dialogReviewVisible = false;
        } else {
          IOT.hideOverlay();
          console.error('error submit!!');
          return false;
        }
      });
    },
    getCaseNowList() {
      IOT.getServerData('/case/details/nowList', 'get', {
        billNo: this.searchData.billNo
      }, (ret) => {
        if (ret.code === 200) {
          if (ret.rows.length > 0) {
            this.formReview.caseDetailId = ret.rows[0].caseDetailId;
          } else {
            this.formReview.caseDetailId = null;
          }
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    showAcceptModal(row) {

      // for(let key in this.formAccept){
      //   if(PF.isNull(row[key])){
      //     this.formAccept[key] = row[key];
      //   }
      // }
      this.formAccept = row;
      this.formAccept.asnBillNo = row.billNo;
      this.formAccept.asnDetailNo = row.detailNo;
      this.formAccept.billType = 2;
      this.formAccept.toQuantity = PF.subtraction(this.formAccept.expectQuantity, this.formAccept.acceptQuantity);
      PF.getSysParamUdfData('acceptExcess', [], (data, udfs) => {
        this.acceptExcess = 1 + (Number(udfs[0].value) / 100);
      });

      this.getPackDetailData(row.packCode);
      this.getBatchTacticData(row);
      this.dialogAcceptVisible = true;
    },
    getBatchTacticData(row) {

      IOT.getServerData('/batch/tactics/findByOrganizationOrItemInfo', 'get', {
        organizationId: row.organizationId,
        itemCode: row.itemCode
      }, (ret) => {

        if (ret.code === 200) {
          let list2 = PF.JSON(ret.rows[0]);
          let detailDetails = list2.batchTacticDetailDetails;
          let testDetailDetails = [];
          list2.batchTacticDetails[12].values = [];
          list2.batchTacticDetails[13].values = [];

          for (let i = 0; i < detailDetails.length; i++) {
            if (detailDetails[i].detailCode === 'batchAttribute1') {
              // testDetailDetails.push(detailDetails[i])
              list2.batchTacticDetails[12].values.push(detailDetails[i])
            } else if (detailDetails[i].detailCode === 'batchAttribute2') {
              list2.batchTacticDetails[13].values.push(detailDetails[i])
            }
          }

          let batchTacticDetails = list2.batchTacticDetails;
          let testBatchTacticDetails = [];
          for (let j = 0; j < batchTacticDetails.length; j++) {
            if (batchTacticDetails[j].state === 1) {
              this.formAccept[batchTacticDetails[j].detailCode] = '';
              testBatchTacticDetails.push(batchTacticDetails[j]);
              if (batchTacticDetails[j].isRequired === 1) {
                this.rulesAccept[batchTacticDetails[j].detailCode][0].required = true;
              }
              if (batchTacticDetails[j].detailCode === 'supplierCode') {
                this.formAccept[batchTacticDetails[j].detailCode] = row.supplierCode;
              } else if (batchTacticDetails[j].detailCode === 'detailNo') {
                this.formAccept[batchTacticDetails[j].detailCode] = row.detailNo;
              } else if (batchTacticDetails[j].detailCode === 'inDate') {
                this.formAccept[batchTacticDetails[j].detailCode] = PF.getNowFormatDate();
              }
            }
          }

          list2.batchTacticDetailDetails = testDetailDetails;
          list2.batchTacticDetails = testBatchTacticDetails;

          this.batchTactics = list2;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    showSelectCellModal() {
      if (!PF.isNull(this.formAccept.wareId)) {
        IOT.tips('未选择仓库！', 'error');
        return false;
      }
      this.searchSelectCell.wareId = this.formAccept.wareId;
      this.getCellData();
      this.getAreaData();
      this.innerVisibleCell = true;
    },
    selectCell(cellCode) {
      this.formAccept.toCellCode = cellCode;
      this.innerVisibleCell = false;
    },
    commitAccept() {
      let that = this;
      IOT.showOverlay('保存中，请稍等...');
      let form = PF.JSON(this.formAccept);
      if (form.toQuantity === 0) {
        IOT.tips('数量不得为零！', 'error', 1000, () => {
          IOT.hideOverlay();
        });
        return false
      }
      if (form.toCellCode === '') {
        IOT.tips('货位不得为空！', 'error', 1000, () => {
          IOT.hideOverlay();
        });
        return false
      }


      form.asnDetailNo = form.detailNo;
      // form[i].toPackDetailId = form[i].packDetailId;
      // form[i].toPackDescribe = form[i].packDescribe;
      form.asnBillNo = form.billNo;

      // 主单位数量
      form.toQuantity = form.toQuantity * form.toTransRatio;
      IOT.getServerData('/accept/records/accept', 'post', this.formAccept, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
          IOT.tips('验收成功！', 'success', 1000, () => {
            that.getAsnDetailData();
            that.getAcceptRecordData();
            that.getQcRecordData();
            that.resetForm('formAccept');
            that.handleClose4();
          });
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      });
    },
    getWares() {
      let param = {
        organizationCode: this.asnMaster.organizationCode
      }
      IOT.getServerData('/ware/infos/findSome', 'get', param, (ret) => {
        IOT.hideOverlay();
        if (ret.code === 200) {
          this.wares = ret.rows;
        } else {
          IOT.tips(ret.message || '服务器请求失败，稍后再试！', 'error');
        }
      })
    },
  },
  created() {
    PF.getRelativePath(this.typeKey, (path) => {
      this.rlPath = path;
    });
    this.getCaseNowList();
    PF.getSysParamUdfData('asnUdfHs', [], (data, udfs) => {
      this.asnUdfHs = udfs;
    });
    PF.getSysParamUdfData('asnUdfDs', [], (data, udfs) => {
      this.asnUdfDs = udfs;
    });
    PF.getSysParamUdfData('systemBottom', [], (data, udfs) => {
      this.companyName = '';
      for (let i = 0; udfs && i < udfs.length; i++) {
        if (udfs[i].paramCode == "companyName") {
          this.companyName = udfs[i].value;
          return;
        }
      }
    });
    this.getAsnMasterData();
    this.getAsnDetailData();
    this.getAcceptRecordData();
    this.getQcRecordData();

    // PF.getDataDictUdfData('asnType', (rows) => {
    //   this.asnTypes = rows;
    // });

  },
  mounted() { },
  updated() {
    // myTable是表格的ref属性值
    if (this.$refs.myTable && this.$refs.myTable.doLayout) {
      this.$refs.myTable.doLayout();
    }
  }
}
</script>

<style scoped lang="less">
.asnBillDetail {}
</style>